<template>
  <el-dialog
    :title="modalType === 'edit' ? '编辑代理商' : '新增代理商'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="代理商名称：" prop="operationName">
        <el-input
          v-model="modalData.operationName"
          placeholder="请输入代理商名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="负责人手机：" prop="phone">
        <el-input
          v-model="modalData.phone"
          placeholder="请输入负责人手机"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="地址：" prop="address">
        <el-input
          v-model="modalData.address"
          placeholder="请输入地址"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="充值比例" prop="chargeScale">
        <el-input
          v-model="modalData.chargeScale"
          placeholder="充值比例，多少钱一天"
        ></el-input>
      </el-form-item>

      <el-form-item label="押金比例" prop="depositScale">
        <el-input
          v-model="modalData.depositScale"
          placeholder="请输入缴纳的押金"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="免费逾期天数" prop="canOverDays">
        <el-input
          v-model="modalData.canOverDays"
          placeholder="请输入免费逾期天数"
          type="number"
        ></el-input>
      </el-form-item>

      <el-form-item label="用户名" prop="userName" v-if="modalType === 'add'">
        <el-input
          v-model="modalData.userName"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>

      <el-form-item label="密码" prop="password" v-if="modalType === 'add'">
        <el-input
          v-model="modalData.password"
          type="password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>

      <el-form-item label="代收金额" prop="collectionMoney">
        <el-input
          v-model="modalData.collectionMoney"
          placeholder="请输入代收金额"
        ></el-input>
      </el-form-item>

      <el-form-item label="代收天数" prop="collectionDays">
        <el-input
          v-model="modalData.collectionDays"
          placeholder="请输入代收天数"
        ></el-input>
      </el-form-item>

      <el-form-item label="开启代收" prop="isCollection">
        <el-checkbox
        false-label="0"
        true-label="1"
          v-model="modalData.isCollection"
        >开启</el-checkbox>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType", "agentList"],
  data() {
    return {
      
      formName: "form",
      ruleValidate: {
        realName: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "change",
          },
        ],

        operationName: [
          {
            required: true,
            message: "请输入代理名称",
            trigger: "change",
          },
        ],

        address: [
          {
            required: true,
            message: "请输入代理商地址",
            trigger: "change",
          },
        ],

        chargeScale: [
          {
            required: true,
            message: "请输入充值比例",
            trigger: "change",
          },
        ],

        deposit: [
          {
            required: true,
            message: "请输入押金",
            trigger: "change",
          },
        ],

        phone: [
          {
            required: true,
            message: "请输入联系人电话",
            trigger: "change",
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "change",
          },
        ],

        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "change",
          },
        ],
      },
    };
  },

  methods: {
    changeType() {
      this.modalData.pid = "";
    },
    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        obj.depositScale = obj.depositScale;
        obj.collectionMoney = obj.collectionMoney && obj.collectionMoney * 100;
        obj.updateAt = '';
        obj.createAt = '';
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
